import React from "react";

/**
 * An animated graphic that plays on repeat
 */
export default function GraphicTwo(): React.ReactElement {
  return (
    <svg
      width="100"
      height="200"
      viewBox="0 0 100 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_103_59)">
        <g style={{ "--order": 0 } as React.CSSProperties}>
          <path
            d="M-0.700359 159.477C32.0738 159.477 46.4543 132.631 46.4543 113.076C46.5654 106.761 43.9867 91.3974 32.7827 80.462C21.5787 69.5267 -29.3905 18.3672 -36.1707 11.4771"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M-0.663514 147.477C24.4401 147.477 35.4548 127.118 35.4548 112.289C35.54 107.501 33.5648 95.8497 24.983 87.557C16.4013 79.2643 -48.8067 14.202 -54 8.97693"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M-0.591286 153.856C28.2008 153.856 40.8341 130.205 40.8341 112.978C40.9318 107.415 38.6664 93.8796 28.8237 84.2457C18.981 74.6119 -41.0861 14.37 -47.0425 8.29986"
            stroke="#618B9C"
            strokeWidth="10"
          />
        </g>
        <g style={{ "--order": 1 } as React.CSSProperties}>
          <path
            d="M-0.740873 171.477C40.4011 171.477 58.4532 137.867 58.4532 113.386C58.5927 105.48 55.3557 86.2458 41.2911 72.5554C27.2265 58.8649 -12.9052 18.6033 -21.4165 9.97717"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M-0.700485 159.477C32.0736 159.477 46.4541 132.674 46.4541 113.151C46.5653 106.846 43.9866 91.5067 32.7826 80.5889C21.5786 69.6711 -30.2623 17.5849 -37.0425 10.7058"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M-0.605204 165.551C36.3238 165.551 52.5273 135.216 52.5273 113.12C52.6526 105.984 49.747 88.624 37.1226 76.2676C24.4983 63.9111 -24.4027 14.7856 -32.0425 7"
            stroke="#33677C"
            strokeWidth="10"
          />
        </g>
        <g style={{ "--order": 2 } as React.CSSProperties}>
          <path
            d="M-0.254349 183.477C48.8893 183.477 70.4524 143.215 70.4524 113.889C70.6191 104.418 66.7524 81.377 49.9524 64.977C33.1524 48.577 6.11906 21.3104 -4.04761 10.9771"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M0.259173 171.477C41.4012 171.477 59.4533 138.083 59.4533 113.76C59.5928 105.905 56.3557 86.7941 42.2911 73.1917C28.2265 59.5894 -12.0312 19.3286 -20.5425 10.7581"
            stroke="white"
            strokeWidth="2.25"
          />
          <path
            d="M-0.259251 177.477C44.718 177.477 64.4529 140.531 64.4529 113.62C64.6055 104.929 61.0666 83.7852 45.6909 68.7358C30.3152 53.6864 -5.13814 17.9594 -14.4429 8.47705"
            stroke="#1F2836"
            strokeWidth="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_103_59">
          <rect width="100" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
