import React from "react";
import styles from "./_graphic.module.scss";

/**
 * An animated graphic that plays on repeat
 */
export default function Graphic(): React.ReactElement {
  return (
    <div className={styles.container + " hiddenStatic"}>
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_91_292)">
          <g style={{ "--order": 0 } as React.CSSProperties}>
            <path
              d="M206.316 137.245L136.615 67.5448C114.02 44.949 83.9635 53.1229 70.0402 67.0463C56.1169 80.9696 47.2294 110.312 70.5387 133.622C93.848 156.931 124.137 147.573 136.876 133.882L206.368 64.3901"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M204.147 150L129.171 75.024C111.804 57.6571 88.6886 63.9542 77.9765 74.6663C67.2645 85.3783 60.4189 107.946 78.3343 125.861C96.2496 143.776 122.864 133.249 132.666 122.716L202 53.3819"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M207.981 146.5L132.697 71.2156C112.56 51.0784 85.8568 58.28 73.5088 70.628C61.1608 82.976 53.3232 109.043 74.0964 129.816C94.8696 150.589 121.777 142.336 133.073 130.192L205 58.2646"
              stroke="#618B9C"
              strokeWidth="10"
            />
          </g>
          <g style={{ "--order": 1 } as React.CSSProperties}>
            <path
              d="M214.667 130.309L143.896 59.5381C115.828 31.4699 78.4561 41.66 61.1341 58.9819C43.8122 76.3038 32.7358 112.789 61.6904 141.744C90.6449 170.698 128.307 159.037 144.157 142.005L214.777 71.3847"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M206.628 136.934L136.819 67.1253C113.82 44.1259 83.2733 52.3991 69.1353 66.5371C54.9974 80.6751 45.9979 110.495 69.7236 134.221C93.4494 157.947 124.23 148.47 137.165 134.567L207.209 64.5236"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M210.129 133.433L139.961 63.2652C114.33 37.6338 80.3414 46.8003 64.6244 62.5172C48.9075 78.2342 38.9316 111.413 65.3724 137.854C91.8133 164.295 126.061 153.79 140.44 138.332L210.375 68.397"
              stroke="#33677C"
              strokeWidth="10"
            />
          </g>
          <g style={{ "--order": 2 } as React.CSSProperties}>
            <path
              d="M221.328 122.234L150.41 51.3159C116.724 17.6298 71.9433 29.788 51.2063 50.525C30.4694 71.2619 17.2474 114.979 51.9972 149.728C86.7471 184.478 131.873 170.556 150.846 150.165L221.848 79.1629"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M213.604 130.665L142.631 59.6918C114.461 31.5216 77.0402 41.6616 59.7188 58.983C42.3973 76.3045 31.3677 112.835 60.4276 141.895C89.4874 170.955 127.196 159.341 143.043 142.308L213.664 71.6864"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M217.596 126.673L146.515 55.5923C115.559 24.636 74.5096 35.7068 55.5275 54.6889C36.5455 73.671 24.4971 113.743 56.4309 145.677C88.3648 177.611 129.728 164.923 147.093 146.254L217.663 75.6847"
              stroke="#1F2836"
              strokeWidth="10"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_91_292">
            <rect
              width="200"
              height="200"
              fill="white"
              transform="translate(0 200) rotate(-90)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
