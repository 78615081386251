import React from "react";

/**
 * An animated graphic that plays on repeat
 */
export default function GraphicTwo(): React.ReactElement {
  return (
    <svg
      width="200"
      height="100"
      viewBox="0 0 200 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_101_31)">
        <g style={{ "--order": 0 } as React.CSSProperties}>
          <path
            d="M132.455 136.616C155.051 114.02 146.877 83.9637 132.954 70.0404C119.03 56.117 89.6877 47.2295 66.3784 70.5389C43.069 93.8482 52.4267 124.137 66.1177 136.876"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M124.976 129.171C142.343 111.805 136.046 88.6889 125.334 77.9769C114.622 67.2648 92.7782 60.169 74.1391 78.3346C55.4999 96.5002 63.4999 120 77.2842 132.666"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M128.784 132.697C148.922 112.56 141.72 85.8569 129.372 73.509C117.024 61.161 90.957 53.3234 70.1838 74.0966C49.4106 94.8698 57.6641 121.777 69.8081 133.073"
            stroke="#618B9C"
            strokeWidth="10"
          />
        </g>
        <g style={{ "--order": 1 } as React.CSSProperties}>
          <path
            d="M140.462 143.896C168.53 115.828 158.34 78.4561 141.018 61.1342C123.696 43.8122 87.2106 32.7358 58.2561 61.6904C29.3015 90.6449 40.9633 128.307 57.9953 144.157"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M132.875 136.819C155.874 113.82 147.601 83.2735 133.463 69.1355C119.325 54.9975 89.5049 45.998 65.7791 69.7238C42.0534 93.4495 51.5297 124.231 65.433 137.165"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M136.735 139.961C162.366 114.33 153.2 80.3415 137.483 64.6245C121.766 48.9076 88.5867 38.9317 62.1458 65.3725C35.705 91.8134 46.2103 126.061 61.6677 140.44"
            stroke="#33677C"
            strokeWidth="10"
          />
        </g>
        <g style={{ "--order": 2 } as React.CSSProperties}>
          <path
            d="M148.684 150.41C182.37 116.724 170.212 71.9434 149.475 51.2064C128.738 30.4695 85.0215 17.2475 50.2716 51.9973C15.5218 86.7472 29.4436 131.873 49.8353 150.846"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M140.308 142.631C168.478 114.461 158.338 77.0404 141.017 59.7189C123.696 42.3975 87.1645 31.3679 58.1047 60.4277C29.0449 89.4876 40.6587 127.196 57.6925 143.043"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M144.408 146.515C175.364 115.559 164.293 74.5097 145.311 55.5276C126.329 36.5455 86.257 24.4971 54.3232 56.4309C22.3893 88.3648 35.0771 129.728 53.7457 147.093"
            stroke="#1F2836"
            strokeWidth="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_101_31">
          <rect width="200" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
